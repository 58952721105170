///// <reference path="../../node_modules/@types/jquery/JQuery.d.ts" />

module OfficeGuy {
    interface PaymentsSettings {
        CompanyID: number;
        APIPublicKey: string;
        ErrorsClass?: string;
        FormSelector?: string;
        Environment?: string;
        ResponseLanguage?: string;
        ResponseCallback?: any;
        Callback?: any;
        IgnoreBind?: any;
    }

    export class Payments {
        static InitEditors(Container = null) {
            jQuery("input[data-og='cardnumber']", Container)
                .off("keyup.officeguy")
                .on("keyup.officeguy", function () {
                    Payments.FormatCardNumber(jQuery(this));
                    return true;
                });
        }
        static FormatCardNumber(Input) {
            var OldValue = Input.val();
            var NewValue = "";
            var Index = 0;
            for (var i = 0; i < OldValue.length; i++) {
                if (OldValue[i] < '0' || OldValue[i] > '9')
                    continue;

                if (Index != 0 && Index % 4 == 0)
                    NewValue += ' ';
                NewValue += OldValue[i];
                Index++;
            }

            if (Input.val() != NewValue)
                Input.val(NewValue);
        }
        public static BindFormSubmit(Settings: PaymentsSettings): void {
            Payments.DefaultSettings(Settings);
            var Form = jQuery(Settings.FormSelector).filter(function () {
                return jQuery(this).data("og-initialized") != "1";
            });
            Form
                .data("og-initialized", "1")
                .off("submit.OfficeGuy")
                .on("submit.OfficeGuy", function () {
                    if (Settings.IgnoreBind != null && Settings.IgnoreBind())
                        return true;
                    return Payments.CreateToken(Settings);
                });
        }

        public static CreateToken(Settings: PaymentsSettings) {
            Payments.DefaultSettings(Settings);
            var Form = jQuery(Settings.FormSelector);
            if (Form.find("[data-og=cardnumber]").length == 0)
                return true;
            if (Form.data("og-ignoreevents") == "1")
                return true;

            // Prevent submittion to server
            Form.find("[data-og]:not([data-og=citizenid])").attr("name", "");

            var CardNumber = Form.find("[data-og=cardnumber]").val();
            var ExpirationMonth = Form.find("[data-og=expirationmonth]").val();
            var ExpirationYear = Form.find("[data-og=expirationyear]").val();
            var CVV = Form.find("[data-og=cvv]").val();
            var CitizenID = Form.find("[data-og=citizenid]").val();
            var Token = Form.find("input[name=og-token]");
            if (Token.length == 0)
                Form.append(Token = jQuery('<input type="hidden" name="og-token" id="og-token" />'));
            if ((<string>Token.val()).length > 0)
                return true;

            Payments.Tokenize(Settings, CardNumber, ExpirationMonth, ExpirationYear, CVV, CitizenID, function (Response) {
                Form.data("og-ignoreevents", "1");
                if (Response.Status != 0) {
                    Form.find(Settings.ErrorsClass).text(Response.UserErrorMessage == null ? Response.TechnicalErrorDetails : Response.UserErrorMessage);
                    if (Settings.ResponseCallback != null)
                        Settings.ResponseCallback(Response);
                    else if (Settings.Callback != null)
                        Settings.Callback(null);
                }
                else {
                    Form.find(Settings.ErrorsClass).text("");
                    var TokenValue = Response.Data.SingleUseToken;
                    Token.val(TokenValue);
                    if (Settings.ResponseCallback != null)
                        Settings.ResponseCallback(Response);
                    else if (Settings.Callback != null)
                        Settings.Callback(TokenValue);
                    else
                        Form.trigger("submit");
                }
                Form.data("og-ignoreevents", "0");
            });
            return false;
        }
        public static Tokenize(Settings: PaymentsSettings, CardNumber, ExpirationMonth, ExpirationYear, CVV, CitizenID, Callback) {
            if (Settings.Environment == null || Settings.Environment == "www")
                Settings.Environment = "api";
            jQuery.ajax({
                url: (Settings.Environment == "dev" ? "http://" : "https://") + Settings.Environment + ".sumit.co.il/creditguy/vault/tokenizesingleuse/",
                type: "post",
                dataType: "json",
                data: {
                    Credentials: {
                        CompanyID: Settings.CompanyID,
                        APIPublicKey: Settings.APIPublicKey
                    },
                    CardNumber,
                    ExpirationMonth,
                    ExpirationYear,
                    CVV,
                    CitizenID
                },
                headers: {
                    'Content-Language': Settings.ResponseLanguage
                },
                success: function (Response) {
                    Callback(Response);
                }
            });
        }
        private static DefaultSettings(Settings: PaymentsSettings) {
            if (Settings.FormSelector == null)
                Settings.FormSelector = "form[data-og=form]";
            if (Settings.Environment == null)
                Settings.Environment = "api";
            if (Settings.ErrorsClass == null)
                Settings.ErrorsClass = ".og-errors";
        }
    }
}

/*jQuery(function () {
    OfficeGuy.Payments.InitEditors();
});*/

module iManage {
    interface PaymentsSettings {
        CompanyID: number;
        APIPublicKey: string;
        ErrorsClass?: string;
        FormSelector?: string;
        Environment?: string;
        Callback?: any;
    }

    export class Payments {
        public static BindFormSubmit(Settings: PaymentsSettings): void {
            return OfficeGuy.Payments.BindFormSubmit(Settings);
        }

        public static CreateToken(Settings: PaymentsSettings) {
            return OfficeGuy.Payments.CreateToken(Settings);
        }
    }
}